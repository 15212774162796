<template>
    <div>        
    <section class='trbaner'>
        <div class="foxj">
            <div class='am-container'>
                <div class='txt'>
                    <div class="am-vertical-align-middle">
                     <p class="tit">动力环境监控解决方案服务商</p>
                     <p class="note">致力于为客户提供专业的动力环境监控解决方案，实现应用场景的赋能。</p>
                </div>
                </div>
            </div>
        </div>
        <img src='../../src/assets/images/解决方案.png'>
    </section>
    <section class="am-path">
        <div class="am-container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/solution' }">解决方案</el-breadcrumb-item>
            <el-breadcrumb-item >{{this.Data.solution_name}}</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
    </section>
    <section class="nybox">
        <div class="am-container">
            <!-- <div class="nyleft">
                <el-col>
                <p class="tit">解决方案</p>
                 <el-menu
                 default-active="2"
                 class="el-menu-vertical-demo">
            <el-menu-item v-for='(item,index) in dataList' :key='index' ><a @click="func(item)">{{item.solution_name}}</a></el-menu-item>
    </el-menu>
  </el-col> 
            </div> -->
            <div class="nyright">
                <div class="nyproxq">
                    <div class="nyprohead">
                        <h1>{{this.Data.solution_name}}</h1>
                    </div>
                    <div class="xiangxi02">
                        <p v-html="this.Data.solution_details"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import {
    getList,
    getOne
} from 'api/GetList'
export default {
    data() {
        return {
            imagesbox:[
                {id:0,idView:require('../assets/images/501.jpg')},
                {id:1,idView:require('../assets/images/502.jpg')},
                {id:2,idView:require('../assets/images/503.jpg')}
            ],
            Data:{},
            dataList:[],
        }
    },
    methods: {
    getList() {
        this.isShowloading = true;
      getList(this.searchInfo).then((res) => {
        this.dataList = res.data.list;
        this.isShowloading = false;
      });
      },
    getOne() {
        let id = this.$route.query.id;
        getOne({fuid: id}).then((res) => {
            this.Data = res.data.entity
        })
    },
    func(item) {
         let id = item.fuid;
        getOne({fuid: id}).then((res) => {
            this.Data = res.data.entity
        })
      }
    },
    mounted() {
        this.getList(),
        this.getOne()
    }
}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
}
.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}
.trbaner .foxj {
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.foxj .txt {
    position: absolute;
    text-align: left;
    left: 1.5rem;
    height: 100%;
    display: table-cell;
    width: 46%;
}
.foxj .txt .tit{
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}
.foxj .note{
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.foxj .txt .am-vertical-align-middle {
       position: relative;
       top: 30%;
}
.am-path {
    height: 50px;
    .el-breadcrumb {
    border: 1px solid #eee;
    font-size: 14px;
    line-height: 50px;
    height: 50px;
    padding-left: 20px;
}
}
.nybox {
    padding: 30px 0;
    background-color: #f5f5f5;
    .nyleft {
        float: left;
        width: 254px;
        
        .tit{
            font-size: 22px;
            padding: 15px 0 15px 25px;
            background: #00579d;
            color: #fff;
        }
        .el-submenu {
            background-color: #f5f5f5;
            margin: 6px 10px;
        }
    }
    .nyright {
        width: 100%;
        float: right;
        background-color: #fff;
        .nyproxq {
            height: 100%;
        }
        .nyproxq::after{
               content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
                -webkit-box-sizing: border-box;
                 box-sizing: border-box; 
        }        
        .nyprohead::after{
               content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
                -webkit-box-sizing: border-box;
                 box-sizing: border-box; 
        }
        .nyprohead {
            padding: 30px;
            display: block;
            height: 100%;
            h1{
                font-size:22px ;
                font-weight: normal;
            }
        }
        .xiangxi02 {
            clear: both;
            overflow: hidden;
            padding: 0 30px 30px 30px;
            text-align: left;
            .hd2 {
                background: url('~images/icon_po.png') 15px center no-repeat #f5f5f5;
                    margin-bottom: 25px;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 60px;
                    padding-left: 50px;
            }
            .xiangxi03 {
                    color: #333;
                    line-height: 2;
                    overflow: hidden;
                }

        }
        .xgcp {
                padding: 15px 0;
                margin: 0 30px;
                font-size: 22px;
                border-bottom: 1px solid #eee;
                text-align: left;
        }
        .xgproli {
            padding: 15px;
                 ul {
                    display: -webkit-flex;
                    display: flex;
                    align-content: space-around;
                    -webkit-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -webkit-align-content: center;
                    align-content: center;
                    justify-content: space-between;
                    li {
                    padding:0 10px ;
                    list-style: none;
                    width: 30%;
                    a{
                        display: block;
                        background: #fff;
                        text-align: center;
                        border: 1px solid #fff;
                        overflow: hidden;
                        i {
                            overflow: hidden;
                        }
                        img {
                            width: 100%;
                            transition: all 0.7s;
                        }
                        img:hover {
                            transform: scale(1.2) ;
                        }
                        .txt{
                            padding:20px;
                        .tit {
                                font-size: 16px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-bottom: 4px;
                                color: #333;
                        }
                        .note {
                                font-size: 12px;
                                margin-bottom: 6px;
                                color: #999;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                        }}
                    }
                    a:hover {
                        border: 1px solid #0e90d2
                    }
                }
                        }
                }
    }
}
.homed {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 3% 0;
    background: url('~images/adbg.jpg') center no-repeat;
    background-size: cover;
}
</style>